import React from "react";

import RichText from "@components/atoms/RichText";

import { RichTextStoryblokProps } from "./types";

const RichTextStoryblok: React.FC<RichTextStoryblokProps> = ({
  blok
}) => {
  const {
    text,
    mobileTextAlignment,
    tabletTextAlignment,
    desktopTextAlignment,
    underlineType
  } = blok;

  return (
    <RichText
      content={text}
      {...{
        mobileTextAlignment,
        tabletTextAlignment,
        desktopTextAlignment,
        underlineType
      }}
    />
  );
};

export default RichTextStoryblok;
